import React from 'react';
import { useState } from 'react';
import Companies from './view/companies'
import Exports from './view/exports';
import Filters from './view/filters';
import * as d3 from 'd3'
function View(props) {
    
    let [inView,setInView] = useState("filters")
    const [clear,setClear] = useState(false)

    const filtersAmount = Object.values(props.filters).length > 0 ? Object.values(props.filters).reduce((acc,el)=>acc.concat( el)).length : 0
    //const filtersL =      Object.values(props.filters).length > 0 && Object.values(props.filters).reduce((acc,el)=>acc.concat(el)).length
    const clearAllFilters = ()=>{ ///extract
        
        setClear(true)
        props.setCompareSector(false)
        Object.values(props.filterDataStructure).forEach(e=>{
            
            //props.update(e.col,[...e.defaultValue])
            e.type.choice==="single"?props.update(e.col,[...e.defaultValue]):props.update(e.col,[])
        })
        
        //props.setSelectedCompany(props.defaultCompany)
        props.setSelectedCompany("")
        //props.setCompanySector(props.defaultCompanySector)
        props.setCompanySector("")
        
        const companies = d3.select("#companies")
        companies.selectAll(".company").attr("data-selected",false)
        companies.select("#initialsList").selectAll("div").attr("data-selected",false)
        
    }
    return (
        <div id="view" data-inview={inView} >
            <div id="toggleView">
                <div id="selectFilters"   
                    onClick={()=>setInView("filters")} 
                    data-selected={inView==="filters"?"true":"false"}>
                        <div className="notification" style={{opacity:filtersAmount<=1?0:1}}>{filtersAmount-1}</div>
                        FILTERS
                </div>
                <div id="selectCompanies" 
                    onClick={()=>setInView("companies")} 
                    data-selected={inView==="filters"?"false":"true"}>
                        {/* <div className="notification" style={{opacity:props.setSelectedCompany===""?0:1}}></div> */}
                        LIST OF COMPANIES
                </div>
                <div id="tgl-selected-before"></div>
            </div>
            <div id="view-body">
                <Companies clear={clear} 
                    list={props.companies} 
                    setSelectedCompany={props.setSelectedCompany} 
                    filters={props.filters} 
                    sector={props.sector} 
                    selectedCompany={props.selectedCompany} 
                    setCompanySector={props.setCompanySector}>
                </Companies>
                <Filters clear={clear} 
                    setClear={setClear} 
                    filterDataStructure={props.filterDataStructure} 
                    update={props.update} 
                    companySector={props.companySector}
                    setCompanySector={props.setCompanySector}
                    filters={props.filters} 
                    availableFilters={props.availableFilters}>
                </Filters>
            <div>
                <div id="clearAllFilters" data-active={filtersAmount>1||props.selectedCompany!==""} onClick={clearAllFilters}></div>
            </div>
            </div>
            {/* <Exports filters={props.filters} company={props.selectedCompany}></Exports> */}
        </div>
    );
}

export default View;