import React from 'react';
import * as d3 from 'd3';
import downloadIcon from '../../../../assets/icons/download.svg'
function DownloadChart() {
    
    function download() {
        //show options
        if(d3.select("#chart").select("svg").node()){
            d3.select("#download-options").style("display","inline-block")
            /* PREPARE SVG DOWNLOAD BUTTON */
            //get svg element.
            const svg = d3.select("#chart").clone("deep").style("display","none").classed("remove",true).select("svg").node();
            //const svg = d3.select("#chart").select("svg").node();
            //get svg source.
            const serializer = new XMLSerializer();
            let source = serializer.serializeToString(svg);
            
            //add name spaces.
            if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
                source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
            }
            if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
                source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
            }
            
            //add xml declaration
            source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
            
            //convert svg source to URI data scheme.
            const url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
            
            //set url value to an element's href attribute.
            //document.getElementById("downloadChart").href = url;
            //you can download svg file by right click menu.
            
            /* PREPARE JPG DOWNLOAD BUTTON */
            
            let svgStyling = d3.select(svg)
                // .attr("transform","translate(50,120)")
                .attr("font-family","sans-serif")
                .style("background-color","#D0DFEA")
                .style("overflow","visible")
                .attr("width",900)
                .attr("height",900)
            svgStyling
                .select("#canvas")
                .attr("transform","scale(0.9) translate(35,60)")
            svgStyling
                .selectAll(".circleValue")
                .attr("fill","#D63D61")
                .attr("fill-opacity",".25")
                .attr("stroke","#D63D61")
            svgStyling
                .selectAll(".circleCenter")
                .attr("fill","#D63D61")
            svgStyling
                .selectAll(".labelText")
                .attr("fill","#D63D61")
                .attr("text-anchor","middle")
                .attr("font-size",14)
            svgStyling
                .selectAll(".labelBg")
                .attr("fill-opacity",".65")
                .attr("rx","5px")
                .attr("fill","#F4F9F9")
            // svgStyling
            //     .select("#labelY")
            //     .attr("transform","translate(0,10)")
            
            let svgWithBg = svgStyling.node()
            const svgString = new XMLSerializer().serializeToString(svgWithBg);
            /* eslint-disable-next-line no-restricted-globals */
            const DOMURL = self.URL || self.webkitURL || self;
            const img = new Image();
            const svgAbs = new Blob([svgString], {type: "image/svg+xml"}); // ;charset=utf-8 // this was breaking in safari
            const appURL = DOMURL.createObjectURL(svgAbs);
            
            img.onload = function() {
                let canvas = document.createElement('canvas');
                canvas.height = 800;
                canvas.width = 800;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, 800, 800);
                const image = canvas.toDataURL("image/jpeg", 1.0);
                document.getElementById("download").href = image;
            };
            
            img.src = appURL;
            d3.selectAll(".remove").remove()
            
        }
    }
    return ( 
    <a  id="download" onMouseOver={download} download="materiality matrix by Koan.jpg">
        <div id="downloadChart" className="downloads" style={{"backgroundImage": `url(${downloadIcon})` }}>
            
        </div>
    </a>
    );
}

export default DownloadChart;